export const GEOLOCATION_LAYER = 'geolocation-layer';
export const LABEL_LAYER = 'label-layer';

export const CLUSTER_LAYER = 'cluster-layer';
export const CLUSTER_COUNT_LAYER = 'cluster-count-layer';

export const POINT_LAYER = 'point-layer';
export const POINT_COUNT_LAYER = 'point-count-layer';
export const ACTIVE_POINT_LAYER = 'active-point-layer';
export const ACTIVE_POINT_SHADOW_LAYER = 'active-point-shadow-layer';
export const NEW_POINT_LAYER = 'new-point-layer';
export const MARKED_POINT_LAYER = 'marked-point-layer';

export const POLYGON_LAYER = 'polygon-layer';
export const ACTIVE_POLYGON_LAYER = 'active-polygon-layer';
export const NEW_POLYGON_LAYER = 'new-polygon-layer';

export const LINE_LAYER = 'line-layer';
export const ACTIVE_LINE_LAYER = 'active-line-layer';
export const NEW_LINE_LAYER = 'new-line-layer';

export const ALL_ACTIVE_CONTAINER_LAYERS = [
  ACTIVE_POINT_LAYER,
  ACTIVE_POINT_SHADOW_LAYER,
  ACTIVE_POLYGON_LAYER,
  ACTIVE_LINE_LAYER,
];
export const ALL_CONTAINER_LAYERS = [POINT_LAYER, POLYGON_LAYER, LINE_LAYER];
export const ALL_NEW_CONTAINER_LAYERS = [NEW_POINT_LAYER, NEW_POLYGON_LAYER, NEW_LINE_LAYER];
