import QueryString from 'qs';
import type { LocationQuery, LocationQueryRaw, RouterOptions } from 'vue-router';

export const defaultRouterOptions: Partial<RouterOptions> = {
  parseQuery: (value: string) => {
    return QueryString.parse(value) as LocationQuery;
  },
  stringifyQuery: (query: LocationQueryRaw) => {
    return QueryString.stringify(query, { encode: false }) as string;
  },
};
