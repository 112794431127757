import { UseGeoJsonSource } from '@libero/mapbox/hooks/sources/useGeoJsonSource';
import { UseMapbox } from '@libero/mapbox/hooks/useMapbox';
import {
  ACTIVE_POINT_LAYER,
  ACTIVE_POINT_SHADOW_LAYER,
  MARKED_POINT_LAYER,
  NEW_POINT_LAYER,
  POINT_COUNT_LAYER,
  POINT_LAYER,
} from '@libero/mapbox/types/layers';
import { DRAWING_SOURCE, GEOJSON_SOURCE } from '@libero/mapbox/types/sources';
import pinWarning from '@libero/theme/icons/map/pin-warning.png';
import { Color } from '@libero/types/Color';

export const usePointLayer = (
  mapbox: UseMapbox,
  geoJsonSource: UseGeoJsonSource,
  color?: Color,
): void => {
  const registerLayers = () => {
    mapbox.map.addLayer({
      id: ACTIVE_POINT_SHADOW_LAYER,
      type: 'circle',
      source: GEOJSON_SOURCE,
      paint: {
        'circle-radius': ['interpolate', ['linear'], ['zoom'], 11, 2.5, 25, 38],
        'circle-color': Color.gray600,
        'circle-blur': 0.75,
      },
      filter: ['all', ['in', 'id', ''], ['!has', 'point_count'], ['==', '$type', 'Point']],
    });

    mapbox.map.addLayer({
      id: ACTIVE_POINT_LAYER,
      type: 'circle',
      source: GEOJSON_SOURCE,
      paint: {
        'circle-radius': ['interpolate', ['linear'], ['zoom'], 11, 2, 25, 30],
        'circle-color': Color.whiteFull,
      },
      filter: ['all', ['in', 'id', ''], ['!has', 'point_count'], ['==', '$type', 'Point']],
    });

    mapbox.map.loadImage(pinWarning, (error, image) => {
      if (error) throw error;
      if (!image) return;

      mapbox.map.addImage('pin-warning', image);

      mapbox.map.addLayer({
        id: MARKED_POINT_LAYER,
        type: 'symbol',
        source: GEOJSON_SOURCE,
        layout: {
          'icon-image': 'pin-warning',
          'icon-size': ['interpolate', ['linear'], ['zoom'], 11, 0.1, 25, 1.2],
          'icon-offset': ['interpolate', ['linear'], ['zoom'], 11, [0, -20], 25, [0, -30]],
          'icon-allow-overlap': true,
        },
        filter: ['all', ['in', 'id', ''], ['!has', 'point_count'], ['==', '$type', 'Point']],
      });
    });

    mapbox.map.addLayer({
      id: POINT_LAYER,
      type: 'circle',
      source: GEOJSON_SOURCE,
      paint: {
        'circle-radius': ['interpolate', ['linear'], ['zoom'], 11, 1.4, 25, 20],
        'circle-color': color || ['get', 'color'],
        'circle-opacity': ['case', ['==', ['get', 'count'], 0], 0.75, 1],
        'circle-stroke-color': [
          'case',
          ['==', ['get', 'count'], 0],
          Color.gray800,
          Color.whiteFull,
        ],
        'circle-stroke-width': ['interpolate', ['linear'], ['zoom'], 11, 0.35, 25, 2.5],
      },
      filter: ['all', ['!has', 'point_count'], ['==', '$type', 'Point']],
    });

    mapbox.map.addLayer({
      id: POINT_COUNT_LAYER,
      type: 'symbol',
      source: GEOJSON_SOURCE,
      minzoom: 16,
      layout: {
        'text-field': ['get', 'count'],
        'text-font': ['Open Sans Bold'],
        'text-size': ['interpolate', ['linear'], ['zoom'], 17, 12, 25, 19],
      },
      paint: {
        'text-color': Color.whiteFull,
      },
      filter: ['all', ['!has', 'point_count'], ['==', '$type', 'Point'], ['>', 'count', 1]],
    });

    if (mapbox.draw) {
      mapbox.map.addLayer({
        id: NEW_POINT_LAYER,
        type: 'circle',
        source: DRAWING_SOURCE,
        paint: {
          'circle-radius': ['interpolate', ['linear'], ['zoom'], 11, 1.4, 25, 20],
          'circle-color': Color.primary600,
          'circle-stroke-color': Color.whiteFull,
          'circle-stroke-width': ['interpolate', ['linear'], ['zoom'], 11, 0.25, 25, 2],
        },
        filter: ['all', ['!has', 'point_count'], ['==', '$type', 'Point']],
      });
    }
  };

  geoJsonSource.onLoad(registerLayers);
};
