import { UseGeoJsonSource } from '@libero/mapbox/hooks/sources/useGeoJsonSource';
import { UseMapbox } from '@libero/mapbox/hooks/useMapbox';
import { LABEL_LAYER } from '@libero/mapbox/types/layers';
import { GEOJSON_SOURCE } from '@libero/mapbox/types/sources';
import { Color } from '@libero/types/Color';

export const useLabelLayer = (mapbox: UseMapbox, geoJsonSource: UseGeoJsonSource): void => {
  const registerLayers = () => {
    mapbox.map.addLayer({
      id: LABEL_LAYER,
      type: 'symbol',
      source: GEOJSON_SOURCE,
      minzoom: 16,
      layout: {
        'text-field': ['get', 'name'],
        'text-offset': [
          'interpolate',
          ['linear'],
          ['zoom'],
          17,
          ['literal', [0, 1.75]],
          25,
          ['literal', [0, 3]],
        ],
        'text-font': ['Open Sans Bold'],
        'text-size': ['interpolate', ['linear'], ['zoom'], 17, 11, 25, 18],
      },
      paint: {
        'text-color': Color.gray600,
        'text-halo-color': Color.whiteOff,
        'text-halo-width': 3,
      },
      filter: ['all', ['==', '$type', 'Point']],
    });
  };

  geoJsonSource.onLoad(registerLayers);
};
