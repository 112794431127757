import { useTenantStore } from '@libero/hooks/store/useTenantStore';
import { useResizeObserver } from '@libero/hooks/useResizeObserver';
import { MapInstance } from '@libero/mapbox/components/Map/Map.types';
import { ALL_CONTAINER_LAYERS } from '@libero/mapbox/types/layers';
import { Color } from '@libero/types/Color';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import Mapbox, { LngLatLike, type Map } from 'mapbox-gl';
import DrawRectangleMode from 'mapbox-gl-draw-rectangle-mode';
import { computed, inject, onMounted, provide, reactive, Ref, ref, watch } from 'vue';

import { getMapStyle } from './tools/useStyle';

type Callback = () => void;

export enum MapboxMode {
  Pointer = 'pointer',
  Drawing = 'drawing',
  Selection = 'selection',
}

export enum MapboxCursor {
  None = '',
  Pointer = 'pointer',
  Crosshair = 'crosshair',
}

export interface UseMapbox {
  map: Map;
  instance?: MapInstance;
  mode: MapboxMode;
  draw?: MapboxDraw;
  isStyleLoaded: boolean;
  setMode: (mode: MapboxMode) => void;
  setCursor: (cursor: MapboxCursor) => void;
  onStyleLoad: (callback: Callback) => void;
}

export const useMapbox = (
  instance: Ref<MapInstance | undefined>,
  isInteractive = false,
  hasActions = false,
): UseMapbox => {
  const tenantStore = useTenantStore();

  const isStyleLoaded = ref(false);
  const map = ref<Map>() as Ref<Map>;
  const cursor = ref(MapboxCursor.None);
  const mode = ref<MapboxMode>(MapboxMode.Pointer);
  const isHovering = ref(false);
  const callbacks = ref<Callback[]>([]);

  const accessToken = computed(() => tenantStore.tenant?.credentials?.mapbox?.access_token);
  const container = computed(() => instance.value?.container);

  useResizeObserver(container, () => {
    map.value?.resize();
  });

  const draw = ref<MapboxDraw>();

  if (hasActions) {
    const color = [
      'case',
      ['boolean', ['get', 'isMeasurement'], false],
      Color.warningBase,
      Color.primary600,
    ];

    draw.value = new MapboxDraw({
      styles: [
        // Line
        {
          id: 'draw-line-primary',
          type: 'line',
          paint: {
            'line-color': color,
            'line-width': 4,
          },
        },
        {
          id: 'draw-line-white',
          type: 'line',
          paint: {
            'line-color': Color.whiteFull,
            'line-width': 3,
          },
        },
        {
          id: 'draw-line-primary-dashed',
          type: 'line',
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': color,
            'line-dasharray': [0, 2.2],
            'line-width': 3,
          },
        },

        // Point
        {
          id: 'draw-point-primary',
          type: 'circle',
          paint: {
            'circle-color': color,
            'circle-radius': 6,
          },
        },
        {
          id: 'draw-point-white',
          type: 'circle',
          paint: {
            'circle-color': Color.whiteFull,
            'circle-radius': 3.5,
          },
        },
      ],
      modes: {
        ...MapboxDraw.modes,
        draw_rectangle: DrawRectangleMode,
      },
    });

    draw.value.modes.DRAW_RECTANGLE = 'draw_rectangle';
  }

  const updateCursor = () => {
    if (MapboxMode.Pointer && isHovering.value) {
      map.value.getCanvas().style.cursor = MapboxCursor.Pointer;
    } else {
      map.value.getCanvas().style.cursor = cursor.value;
    }
  };

  const setCursor = (newCursor: MapboxCursor) => {
    cursor.value = newCursor;
    updateCursor();
  };

  const setMode = (newMode: MapboxMode) => {
    mode.value = newMode;

    if (draw.value && mode.value === MapboxMode.Pointer) {
      draw.value.changeMode(draw.value.modes.SIMPLE_SELECT);
      setCursor(MapboxCursor.None);
    }
  };

  const center = computed(() => {
    return (tenantStore.tenant?.center.coordinates || [
      5.438698212082399, 51.624493035890396,
    ]) as LngLatLike;
  });

  const register = () => {
    if (!container.value) return;
    if (!accessToken.value) return;
    if (map.value) return;

    Mapbox.accessToken = accessToken.value;

    map.value = new Mapbox.Map({
      container: container.value,
      style: getMapStyle(),
      zoom: tenantStore.tenant?.zoom || 13,
      interactive: isInteractive,
      center: center.value,
    });

    if (isInteractive) {
      if (draw.value) {
        map.value.addControl(draw.value);
      }

      map.value.on('mouseenter', ALL_CONTAINER_LAYERS, (event) => {
        if (!event.features?.at(0)?.properties?.id) return;

        isHovering.value = true;
        updateCursor();
      });

      map.value.on('mouseleave', ALL_CONTAINER_LAYERS, () => {
        isHovering.value = false;
        updateCursor();
      });
    }

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') setMode(MapboxMode.Pointer);
    });
  };

  const onStyleLoad = (callback: Callback) => {
    callbacks.value.push(callback);
  };

  onMounted(register);
  watch(accessToken, register);
  watch(container, register);

  watch(map, (_, oldValue) => {
    if (!oldValue) {
      map.value.on('style.load', () => {
        isStyleLoaded.value = true;
        callbacks.value.forEach((callback) => callback());
      });
    }
  });

  const mapbox = reactive({
    map,
    instance,
    draw,
    mode,
    isStyleLoaded,
    setMode,
    setCursor,
    onStyleLoad,
  });

  provide('mapbox', mapbox);

  return mapbox;
};

export const useMapboxContext = (): UseMapbox => {
  const mapbox = inject<UseMapbox>('mapbox');

  if (!mapbox) {
    throw new Error('Mapbox context can only be used when mapbox is used in a parent component.');
  }

  return mapbox;
};
